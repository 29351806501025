<template>
  <div class="footer-menu">
    <div class="footer">
      <section class="menu" @click="goto('/')">
        <section class="up">
          <!-- <van-icon name="home-o" /> -->
          <img v-if="activePath == '/'" :src="homeActiveImg" />
          <img v-else :src="homeImg" />
        </section>
        <section class="down" :class="{ active: activePath == '/' }">
          首页
        </section>
      </section>

      <!-- <section class="menu" @click="goto('/tiku')">
        <section class="up">          
          <img v-if="activePath == '/tiku'" :src="tikuActiveImg" />
          <img v-else :src="tikuImg" />
        </section>
        <section class="down" :class="{ active: activePath == '/tiku' }">
          题库
        </section>
      </section> -->

      <section class="menu" @click="goto('/userCourse')">
        <section class="up">
          <!-- <van-icon name="medal-o" /> -->
          <img v-if="activePath == '/userCourse'" :src="myCourseActiveImg" />
          <img v-else :src="myCourseImg" />
        </section>
        <section class="down" :class="{ active: activePath == '/userCourse' }">
          我的课程
        </section>
      </section>

      <section class="menu" @click="goto('/profile')">
        <section class="up">
          <!-- <van-icon name="friends-o" /> -->
          <img v-if="activePath == '/profile'" :src="profileActiveImg" />
          <img v-else :src="profileImg" />
        </section>
        <section class="down" :class="{ active: activePath == '/profile' }">
          个人中心
        </section>
      </section>
    </div>
    <div class="beian" v-if="isMobileMode == false">
      <div class="item">
        <a href="http://beian.miit.gov.cn" target="_blank">桂ICP备2022006803号</a>
      </div>
      <!-- <a style="margin-left:20px" href="http://aida101.com/privacy" target="_blank">隐私策略</a>
      <a style="margin-left:20px" href="http://aida101.com/about" target="_blank">关于</a> -->
      <div class="item">
        <img
          :src="gonganIcon"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010202000602"
        />

        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010202000603">
          桂公网安备 45010202000603号</a
        >
      </div>
    </div>
    <loginWidgetV2 v-if="showLoginWidget"></loginWidgetV2>
  </div>
</template>

<script>
import loginWidgetV2 from "@/components/loginWidgetV2.vue";
import { isMobile, isWeiXin } from "@/util/browser.js";
import { userCenterApi } from "@/api/userCenterApi.js";
export default {
  components: {
    loginWidgetV2,
  },
  data() {
    return {
      showLoginWidget: false,
      homeImg: require("@/assets/images/footer/home.png"),
      homeActiveImg: require("@/assets/images/footer/home-active.png"),
      tikuImg: require("@/assets/images/footer/tiku.png"),
      tikuActiveImg: require("@/assets/images/footer/tiku-active.png"),
      myCourseImg: require("@/assets/images/footer/myCourse.png"),
      myCourseActiveImg: require("@/assets/images/footer/myCourse-active.png"),
      profileImg: require("@/assets/images/footer/profile.png"),
      profileActiveImg: require("@/assets/images/footer/profile-active.png"),
      activePath: "/",
      isMobileMode: false,
      gonganIcon: require("@/assets/images/gongan.png"),
    };
  },
  methods: {
    goto(path) {
      if (path == "/") {
        this.$router.push({
          path: path,
          query: {
            id: 1,
          },
        });
      } else if (path == "/tiku" || path == "/userCourse" || path == "/profile") {
        // 如果登录了才跳转、否则弹出登录
        userCenterApi
          .uid()
          .then((ret) => {
            if (ret.code == 0 && ret.data) {
              this.$router.push({
                path: path,
              });
            } else {
              localStorage.clear();
              this.showLoginWidget = true;
            }
          })
          .catch((err) => {
            this.$member.logoutV2().then((ret) => {
              localStorage.clear();
              this.showLoginWidget = true;
            });
          });
      }
    },
    listenOnCloseLoginWidget() {
      this.$EventBus.$on("onCloseLoginWidget", (val) => {
        this.showLoginWidget = false;
      });
    },
  },
  mounted() {
    this.listenOnCloseLoginWidget();
    this.$nextTick(() => {
      this.activePath = this.$route.path;
    });
    const isWeiXinVal = isWeiXin();
    const isMobileVal = isMobile();
    this.isMobileMode = isWeiXinVal || isMobileVal;
  },
};
</script>

<style lang="less" scoped>
.footer-menu {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .footer {
    height: 68px;
    flex-shrink: 0;
    background: #ffffff;
    width: 100%;
    border-top: 1px solid #d1d1d1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .up {
        margin: 5px 0px 0px 0px;
        font-size: 30px;
        width: 30px;
        text-align: center;
        img {
          width: 26px;
          height: auto;
        }
      }
      .down {
        font-size: 11px;
        color: #323233;
        letter-spacing: 0.28px;
      }
      .active {
        color: #4d52d1;
      }
    }
  }
  .beian {
    text-align: center;
    display: flex;
    flex-direction: row;
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0px 5px 20px;
      a {
        text-decoration: none;
        color: #1a2a3a;
        &:hover {
          color: #1a2a3a;
        }
        &:visited {
          color: #1a2a3a;
        }
      }
    }
  }
}
</style>
